
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "overview",
  components: {},
  data(): {
    startDate: string;
    endDate: string;
  } {
    return {
      startDate: "",
      endDate: "",
    };
  },
  created() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("供應商資料輸入", ["產線管理自動排程資訊"]);
    });
  },
  // setup() {},
});
